import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EmployeeAttendanceSummary } from '../models/EmployeeAttendanceSummary';
import { EmployeeMaster } from '../models/employeeMasterts';
import { AttendanceService } from '../services/attendance.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';
import { HttpParams } from '@angular/common/http';
import { ConsolidatedReportComponent } from './consolidated-report/consolidated-report.component';
import { LuceneSearchService } from '../services/lucene-search.service';
import { DetailedReportComponent } from './detailed-report/detailed-report.component';

declare var $: any;

@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.css']
})
export class AttendanceReportComponent implements OnInit, OnChanges {

  @ViewChild(ConsolidatedReportComponent) consolidateComponent!: ConsolidatedReportComponent;
  @ViewChild(DetailedReportComponent) detailedReportComponent!: DetailedReportComponent;

  @ViewChild('attendanceReport') attendanceReport: AttendanceReportComponent;



  @Input() hide: any = null;
  @Input() startOfMonth: string = null;
  @Input() endOfMonth: string = null;

  keyinempid: any | undefined;
  employeeid: String;
  queryName: any;
  query: any;
  fromDate: string = null
  toDate: string = null
  currentDate: string;

  reportType: string = "Consolidated Report";
  // reportType: string = "Attendance Matrix By Hours";
  nowDetailedReport: boolean = false;
  selectedMatrix: boolean = false;
  nowConsolidateReport: boolean = true;
  daysArray: any[] = [];
  dayOfWeekArray: any[] = [];
  ActiveInativeLuceneFilter: string = 'Active';
  employeeMasterLucene: EmployeeMaster[];
  activeExitFilters: string = "Active";
  listingFilters: string = "GBB";
  odd_Leave: string = "All";
  filteredBranchInfo: any[] = [];

  shift_setting: string;

  showSearchBar: boolean = true;  // search bar , if true = visible

  leaveTypeMap = new Map();
  client_id: EmployeeMaster[];

  consolidatedReport: EmployeeMaster[];
  detailedConsolidatedReport: EmployeeMaster[];
  attendanceMatrixReport: EmployeeMaster[] = [];
  tempAttendanceMatrixReport: any;
  byStatusorHour: boolean = true;

  detailedReportData: EmployeeAttendanceSummary[];
  detailReportInConsolidateReport: EmployeeAttendanceSummary[];

  contractstream$!: Subscription;
  receivedData: any;
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  empIdList: any;

  //// remove
  employeeId: any;
  employeeName: any;
  monthName: any;
  branchInfo: any;

  branch: any = "all";
  selectedempid: any;

  limitPara: any;
  offsetPara: any;
  clearChild: number = 0;
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  showBasedOnPermissionEmployeeBranch: boolean = false;
  loggedInUserBranchId: any;
  // clearChild: boolean = false;

  constructor(private empLuceneSearch: LuceneSearchService, private _snackBar: MatSnackBar, private empLeaveService: EmployeeLeaveService, private attendanceService: AttendanceService, private employeeService: EmployeeAttendenceProxyService) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("Changes detected:", changes);
    console.log("check ", this.fromDate);
    console.log("check ", this.toDate);
  }

  ngOnInit(): void {
    this.loggedInUserBranchId = sessionStorage.getItem("branchId");
    this.activeExitFilters = "Active";
    this.ActiveInativeLuceneFilter = "Active";
    this.shift_setting = "Setting";
    this.getAllBranches();
    this.limitPara = 15;
    this.offsetPara = 0;

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
  }

  changeDates() {
    this.reportType = "Attendance Matrix By Hours";
    if (this.reportType === "Attendance Matrix By Hours") {
      this.byStatusorHour = true;
    } else {
      this.byStatusorHour = false;
    }
    this.onAttendanceReportTypeChange(this.reportType);
    this.fromDate = this.convertDateToInputFormat(this.startOfMonth);
    this.toDate = this.convertDateToInputFormat(this.endOfMonth);
  }

  convertDateToInputFormat(dateString: string): string {
    const [day, month, year] = dateString.split('-');
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return formattedDate;
  }

  // getEmployeeByNameOrId() {
  //   const [name, id] = this.query.split(' / ');
  //   this.queryName = name;
  //   this.employeeid = id;

  //   console.log("query", this.queryName);

  //   console.log("Len ", this.queryName);
  //   if (this.queryName.length <= 2) {
  //     this.employeeMaster = [];
  //   }

  //   if (this.queryName.length >= 3) {
  //     this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
  //       data => {
  //         console.log("Emp Data ", data);

  //         // Check if data.employeeMasterData has any entries
  //         if (data.employeeMasterData.length > 0) {
  //           let filteredEmployees = data.employeeMasterData;

  //           // Get the current date for comparison
  //           const currentDate = new Date();
  //           console.log("Current Date: ", currentDate);  // Log current date for debugging

  //           // Apply filtering logic based on the selected employee status
  //           if (this.ActiveInativeLuceneFilter === 'Active') {
  //             filteredEmployees = filteredEmployees.filter(emp => {
  //               const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
  //               console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
  //               // Active employees should have leavingdate in the future or null
  //               return leavingDate === null || leavingDate > currentDate;
  //             });
  //           } else if (this.ActiveInativeLuceneFilter === 'Inactive') {
  //             filteredEmployees = filteredEmployees.filter(emp => {
  //               const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
  //               console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
  //               // Inactive employees should have leavingdate in the past or null
  //               return leavingDate && leavingDate < currentDate;
  //             });
  //           }

  //           // If the status is 'All', no filter is applied
  //           if (this.ActiveInativeLuceneFilter === 'All') {
  //             filteredEmployees = data.employeeMasterData;
  //           }

  //           // Assign filtered data to the employeeMasterLucene
  //           this.employeeMasterLucene = filteredEmployees;

  //           // Log the data and leaving date of the first employee in the filtered list
  //           if (this.employeeMasterLucene.length > 0) {
  //             this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
  //             console.log("Filtered Employee Data: ", this.employeeMasterLucene);
  //             console.log("leavingdate", this.leavingdate);
  //           } else {
  //             console.log("No employee data found.");
  //           }
  //         } else {
  //           console.log("No employee data found.");
  //         }

  //         // Update the employeeMaster with filtered employees after status check
  //         this.employeeMaster = this.employeeMasterLucene;
  //         let filteredEmployees = data.employeeMasterData;
  //         const selectedEmployee = filteredEmployees.find(emp => emp.employeeid === this.employeeid);
  //         // Set the selected employee ID
  //         if (selectedEmployee) {
  //           this.selectedempid = selectedEmployee.uniqueemployeeid;
  //         }
  //       },
  //       error => {
  //         console.log("Error ", error);
  //       }
  //     );
  //   }
  // }

  getEmployeeByNameOrId() {
    this.empLuceneSearch.getEmployeeByNameOrId(this.queryName, this.employeeid, this.ActiveInativeLuceneFilter, this.query, this.employeeMasterLucene, this.leavingdate, this.selectedempid).subscribe(res => {
      console.warn(res)
      this.employeeMasterLucene = res.filteredEmployees;
      this.selectedempid = res.selectedempid;
    });
  }

  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    this.showBasedOnPermissionEmployeeBranch = this.permissionList.includes('ng.screen.master.showbranch');
  }

  generateDaysArray(): void {
    const startDate = new Date(this.fromDate);
    const endDate = new Date(this.toDate);
    const daysArray: any[] = [];
    const dayOfWeekArray: any[] = []; // Array for day of the week characters

    // Create a copy of the start date to avoid modifying it
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Get the day of the month as a string and push it to the array
      const dayOfMonth = currentDate.getDate().toString().padStart(2, '0');
      daysArray.push(dayOfMonth);

      // Get the day of the week as a number (0 = Sunday, 1 = Monday, etc.)
      const dayOfWeek = currentDate.getDay();

      // Convert the day of the week number to its one-character representation
      const dayOfWeekChar = ['S', 'M', 'T', 'W', 'T', 'F', 'S'][dayOfWeek];
      dayOfWeekArray.push(dayOfWeekChar);

      // Increment the current date by one day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.daysArray = daysArray;
    this.dayOfWeekArray = dayOfWeekArray; // Store day of the week characters
  }

  search() {
    this.offsetPara = 0;
    this.clearChild = 0;
    if (this.consolidateComponent) {
      this.consolidateComponent.offsetPara = 0;
    }
    console.log("brfore calling attendnace report function ", this.clearChild);

    if (this.detailedReportComponent) {
      this.detailedReportComponent.offsetPara = 0;
    }
    console.log("brfore calling attendnace report function ", this.clearChild);
  }


  childToPar(info: number) {
    this.offsetPara = info;
    console.log("value from child to parent for offset increment :", info);
    console.log("clear child in parent is ", this.clearChild);
    this.clearChild = info;
    this.getAttendenceReport();
  }

  onScroll() {
    this.offsetPara = this.offsetPara + 15;

  }

  getAttendenceReport() {

    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;

    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      this.branch = "all";
    }

    // console.log("Employee parent" + employeename);
    // console.log("Report Type parent", this.reportType)
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMasterLucene.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.selectedempid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }



    if (this.reportType === "Attendance Matrix By Hours" || this.reportType === "Attendance Matrix By Status") {
      if (this.reportType === "Attendance Matrix By Hours") {
        this.byStatusorHour = true;
      } else {
        this.byStatusorHour = false;
      }
      console.log("clicked getAttendenceMatrix button");
      console.log("11111111111111111111111111111111  : ", this.branch)
      this.getAttendenceMatrix(this.reportType, this.fromDate, this.toDate, this.activeExitFilters, this.listingFilters, this.limitPara, this.offsetPara)
      return;
    }

    console.log("all ( detailreport , consolidate report ) : ")
    console.log("Values to send >>", this.reportType, this.fromDate, this.toDate, 'Curent Date >', this.currentDate, "id : ", id, "shift : ", this.shift_setting, "branch ", this.branch, "keyedinid ", this.keyinempid, "selectedempid", this.selectedempid)
    this.contractstream$ = this.attendanceService.getAttendenceReport(this.reportType, this.fromDate, this.toDate, this.currentDate, this.ActiveInativeLuceneFilter, id, this.shift_setting, this.branch, this.limitPara, this.offsetPara).subscribe(
      data => {
        console.log("Attedance report parent  : ", data)
        if (this.reportType === "Detailed Report") {
          console.log("selectd is type detailed true parent :", this.reportType)
          // this.detailedReport = data.attendanceDetails[0].attendanceList; //
          // all 
          this.detailedReportData = []; // Initialize an empty array to hold all concatenated items

          for (let i = 0; i < data.attendanceDetails.length; i++) {
            this.detailedReportData = this.detailedReportData.concat(data.attendanceDetails[i].attendanceList);
          }

          // this.detailedConsolidatedReport = data.attendanceDetailsConsolidate;
          console.log("detail report response here : : :  ", this.detailedReportData)
          console.log("detailed report + consolidate report : : ", this.detailReportInConsolidateReport)
        } else {
          console.log("selectd is type consolidate true parent :", this.reportType)
          this.consolidatedReport = data.attendanceDetailsConsolidate;
          console.log(" consolidatedReport parent", this.consolidatedReport)
        }
        console.log("grid move up");
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.log(error);
      }
    );
  }

  getAttendenceMatrix(reportType, fromDate, toDate, activeExitFilters, listingFilters, limitPara, offsetPara) {

    console.log("222222222222222222222222222222  : ", this.branch)
    this.generateDaysArray();
    console.log("clicked getAttendenceMatrix button");
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    const fromDateParts = this.fromDate.split('-');
    const monthIndex = parseInt(fromDateParts[1]) - 1;
    this.monthName = monthNames[monthIndex];
    var id = null;
    console.log("attendance matrix")
    console.log(" data to pass getAttendenceMatrix ", "RT ", reportType, "FD ", fromDate, "TD ", toDate, "ID ", id, "activefilter", activeExitFilters, "shift", this.shift_setting, "branch ", this.branch);

    this.contractstream$ = this.attendanceService.getAttendenceMatrix(this.reportType, this.fromDate, this.toDate, id, this.activeExitFilters, this.shift_setting, this.branch, listingFilters, limitPara, offsetPara).subscribe(
      data => {
        console.log("Attedance Matrix report parent  : ", data)

        if (this.odd_Leave === "Odd") {
          if (this.offsetPara == 0) {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = data.attendanceDetails;
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) { // iterate through name / each employee
              console.log("Loop inside this.tempAttendanceMatrixReport:", i, ": index  :", this.tempAttendanceMatrixReport[i]);
              console.log("Loop inside this.tempAttendanceMatrixReport  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {  // iterate through attendace list of each emp 
                console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:  hour : ", this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour);

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour === '00:00:00') { // check condition 
                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  // childLoopList.push(this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          } else {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = this.tempAttendanceMatrixReport.concat(data.attendanceDetails);
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) { // iterate through name / each employee
              // console.log("Loop inside this.tempAttendanceMatrixReport:", i, ": index  :", this.tempAttendanceMatrixReport[i]);
              console.log("Loop inside this.tempAttendanceMatrixReport  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {  // iterate through attendace list of each emp 
                // console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                // console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:  hour : ", this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour);

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour === '00:00:00') { // check condition 
                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  // childLoopList.push(this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          }

        }
        else if (this.odd_Leave === "Leave") {
          if (this.offsetPara == 0) {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = data.attendanceDetails;
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) {
              console.log("Loop inside resp  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {
                console.log(" ")
                console.log("Inside loop resp[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                console.log(" ")

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].annualHoliday === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].weeklyHolidays === 0 &&
                  this.tempAttendanceMatrixReport[i].attendanceList[j].leaveTaken === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysPresent === 0
                  && this.tempAttendanceMatrixReport[i].attendanceList[j].oddPunch === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysAbsent === 1) {

                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          } else {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = this.tempAttendanceMatrixReport.concat(data.attendanceDetails);
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) {
              console.log("Loop inside resp  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {
                console.log(" ")
                console.log("Inside loop resp[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                console.log(" ")

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].annualHoliday === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].weeklyHolidays === 0 &&
                  this.tempAttendanceMatrixReport[i].attendanceList[j].leaveTaken === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysPresent === 0
                  && this.tempAttendanceMatrixReport[i].attendanceList[j].oddPunch === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysAbsent === 1) {

                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          }
        }
        else {
          if (this.offsetPara == 0) {
            this.attendanceMatrixReport = data.attendanceDetails;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          } else {
            this.attendanceMatrixReport = this.attendanceMatrixReport.concat(data.attendanceDetails);
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport);
          }
        }
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.log(error);
      }
    );
  }

  onAttendanceReportTypeChange(selectedValue: string) {
    this.showSearchBar = true;  // search bar , if true = visible
    this.reportType = selectedValue;
    this.selectedMatrix = false;
    this.nowDetailedReport = false;
    this.nowConsolidateReport = false;

    if (this.reportType === "Attendance Matrix By Hours" || this.reportType === "Attendance Matrix By Status") {
      if (this.reportType === "Attendance Matrix By Hours") {
        this.byStatusorHour = true;
      } else {
        this.byStatusorHour = false;
      }
      this.selectedMatrix = true;
      this.showSearchBar = false;  // search bar , if true = visible
      this.attendanceMatrixReport = [];
      console.log(" / F", this.selectedMatrix)
    } else if (this.reportType === "Detailed Report") {
      this.showSearchBar = true;  // search bar , if true = visible
      this.nowDetailedReport = true;
      this.consolidatedReport = [];
      // this.branch = "all";
      // Filter branches based on the logged-in user's branchId
      if (!this.showBasedOnPermissionEmployeeBranch) {
        const loggedInUserBranch = this.branchInfo.find(branch => branch.branchId == this.loggedInUserBranchId);

        if (loggedInUserBranch) {
          this.branch = loggedInUserBranch.branchId; // Set the branch for the logged-in user
          this.branchInfo = [loggedInUserBranch]; // Show only this branch in the dropdown
        }
      }
    } else {
      this.showSearchBar = true;  // search bar , if true = visible
      this.nowConsolidateReport = true;
      this.consolidatedReport = [];
      this.consolidateComponent.consolidatedReport = [];
      this.detailedReportComponent.detailedReportData = [];
      this.detailedReportData = [];
    }
    console.log("Value", this.reportType)

  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();
    this.clearOnchange();

    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }

  }

  ngOnDestroy(): void {
    if (this.contractstream$) {
      this.contractstream$.unsubscribe();
    }
  }

  clear() {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.query = "";
    this.clearOnchange();

  }

  clearOnchange() {
    this.tempAttendanceMatrixReport = [];
    this.attendanceMatrixReport = []; // empty matrix response 
    this.detailedReportData = []; // detail report 
    this.detailedConsolidatedReport = []; // detailed consolidate report 
    this.consolidatedReport = []; // consolidate report 
    // this.clearChild = true;
    this.clearChild = 0;
    console.log("clear child after change ", this.clearChild);
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>");
    this.employeeService.getAllBranchsActive().subscribe(
      data => {
        console.log("check this");
        this.branchInfo = data.branches;

        // Filter branches based on the logged-in user's branchId
        if (!this.showBasedOnPermissionEmployeeBranch) {
          const loggedInUserBranch = this.branchInfo.find(branch => branch.branchId == this.loggedInUserBranchId);

          if (loggedInUserBranch) {
            this.branch = loggedInUserBranch.branchId; // Set the branch for the logged-in user
            this.branchInfo = [loggedInUserBranch]; // Show only this branch in the dropdown
          }
        }
      },
      error => {
        console.error("Error fetching branches", error);
      }
    );
  }



  onBranchChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.branch = selectElement.value;
    console.log('Branch changed to: ', this.branch);
    this.clearOnchange();

  }

  onActiveExitFilterChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.activeExitFilters = selectElement.value;
    console.log('Active/Exited filter changed to: ', this.activeExitFilters);
    this.clearOnchange();
  }

  onOddLeaveFilterChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.odd_Leave = selectElement.value;
    console.log('Odd/Leave filter changed to: ', this.odd_Leave);
    this.clearOnchange();
  }

  onListingFilterChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.listingFilters = selectElement.value;
    console.log('Listing filter changed to: ', this.listingFilters);
    this.clearOnchange();
  }

  // filterBranches() {
  //   if (this.showBasedOnPermissionEmployeeBranch) {
  //     // Show only the logged-in user's branch
  //     const branchId = sessionStorage.getItem("branchId");
  //    console.log("Logged-in User Branch ID:", branchId);

  //     this.filteredBranchInfo = this.branchInfo.filter(branch => branch.branchId === branchId);
  //   } else {
  //     // Show all branches
  //     this.filteredBranchInfo = [...this.branchInfo];
  //   }

}
