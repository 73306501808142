<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp"></div>
    <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Employee Joining Report </div>
            </div>
        </div> -->

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form id="fcForm" class="form-horizontal form-label-left" #f="ngForm" (ngSubmit)="f.form.valid">
              <div class="x_content row px-4">
                <div class="col-md-4 col-sm-4 col-xs-4">
                  <label class="form-label w-100 mb-1">Report Type</label>
                  <div class="form-group m-0">
                    <div>
                      <select id="reporttype" class="form-control" name="reporttype" placeholder="Select Report Type"
                        [(ngModel)]="reportType" autocomplete="off" autofocus="autofocus" (change)="resetRecords()">

                        <option value="JR">Joining Report</option>
                        <option value="ER">Exit Report</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="col-md-4 col-sm-4 col-xs-4 ">
                  <div class="form-group label-floating ">
                    <label class="form-label"> From Date: </label>
                    <div>
                      <input class="form-control" type="date" name="fromdate" id="fromdate" [(ngModel)]="fromdate">
                      <!-- <select class="form-control " style="appearance: auto !important;"
                                  data-style="btn btn-drpdwn btn-round" id="dGroup" name="shift"
                                 [(ngModel)]="shift"
                                  > -->
                      <!-- [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required -->
                      <!-- <option [value]="null" selected>Select Shifts</option>
                                  <option  >shift1</option>
                                  <option  >shift3</option> -->

                      <!-- </select> -->
                      <!-- <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                                            Please Select Document Group</span> -->
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-4 ">
                  <div class="form-group label-floating ">
                    <label class="form-label"> To Date: </label>
                    <div>
                      <input class="form-control" type="date" name="todate" id="todate" [(ngModel)]="todate">
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4">
                  <div class="d-flex justify-content-start align-items-center mt-3">
                    <button type="button" id="btnsave" [disabled]="false" class="btn btn-primary w-100  p-2 p-md-1 "
                      (click)="reset();search() ">
                      <i class="fa fa-search" aria-hidden="true"></i> Search
                    </button>
                    <button class="btn btn-danger w-100 ms-2" type="button" id="btnsave"
                      (click)="onClear()">Clear</button>
                  </div>
                </div>

              </div>



              <!-- <div class="actionBar">
                      <div class="clearfix"> </div>
                      <div class="pro-fab">
                       <button class="btn btn-success" type="button" [disabled]="false"  id="btnsave" (click)="reset();search()">Search</button> -->
              <!-- <button class="btn btn-warning ms-2" type="button" id="btnsave"  (click)="update(f.form.value)">Update</button> -->
              <!-- <button class="btn btn-danger ms-2" type="button" id="btnsave" >Clear</button>
                      </div>
                    </div>  -->

            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header  border-0">
            <h4 class="card-title">Employee Details</h4>
            <button type="button" class="btn btn-primary ms-auto" (click)="downloadEmployeeReport()"><i
                data-bs-toggle="tooltip" data-original-title="download" class="fa fa-download"></i></button>
          </div>
          <div class="card-body">
            <div class="col-xs-12 mobile">
              <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                (scrolled)="onScroll()">
                <div class="scrollbar">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="stickyatTop rowcolors">
                        <th class="border-bottom-0 w-10 fs-7">#</th>
                        <th class="border-bottom-0 w-10 fs-7">Employee Name</th>
                        <th class="border-bottom-0 w-10 fs-7">Branch </th>
                        <th class="border-bottom-0 w-10 fs-7">Email </th>
                        <th class="border-bottom-0 w-10 fs-7">Phone No.</th>

                        <th class="border-bottom-0 w-10 fs-7" *ngIf="reportType=='JR'"> Joining Date</th>
                        <th class="border-bottom-0 w-10 fs-7" *ngIf="reportType=='ER'"> Exit Date</th>
                      </tr>
                    </thead>
                    <tbody class="tablerow">
                      <tr *ngFor="let info of employeemasterinfos;let i=index">
                        <td data-th="Employee Id" style="width: max-content;" style="text-align: right;">{{i+1}}</td>
                        <td data-th="Shift" style="width:  20%;word-wrap: break-word;">{{info.name}}</td>
                        <td data-th="Shift" style="width:  20%;word-wrap: break-word;">{{info.branchName}}</td>
                        <td data-th="Shift" style="width:  20%;word-wrap: break-word;">{{info.email}}</td>
                        <td data-th="Shift" style="width:  20%;word-wrap: break-word;">{{info.mobile}}</td>
                        <td *ngIf="reportType=='JR'" data-th="From Date" style="width:  20%;word-wrap: break-word;">
                          {{info.joiningdate | date:'mediumDate'}}</td>
                        <td *ngIf="reportType=='ER'" data-th="From Date" style="width:  20%;word-wrap: break-word;">
                          {{info.leavingdate | date:'mediumDate'}}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>