<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">


                <div class="card">
                    <div class="card-body row">

                        <div class="col-md-3 col-sm-3 col-xs-3 ps-5">
                            <div class="form-group label-floating">
                                <label class="form-label">Route Name<span style="color: rgb(241, 111, 111);">
                                        &nbsp;*</span></label>
                                <div class="form-group m-0">
                                    <div>
                                        <select id="deptInput" class="form-control" name="branch"
                                            placeholder="Select Branch" [(ngModel)]="routeName"
                                            (change)="onRouteSelection()" autocomplete="off" autofocus="autofocus">
                                            <option value="all" selected="selected">Select Route</option>
                                            <option value={{data.id}} *ngFor="let data of route">
                                                {{data.routename}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating">
                                <label class="form-label">Assigned To</label>
                                <div>
                                    <input type="search" class="form-control col-md-12 col-xs-12" name="Employee Name"
                                        id="employeename" placeholder="Enter Employee Name / ID" [(ngModel)]="query"
                                        autocomplete="off" (input)="getEmployeeByNameOrId(info)"
                                        list="employeeMasterLucene" autofocus="autofocus">
                                    <datalist id="employeeMasterLucene">
                                        <option [value]="info.name + ' / ' + info.employeeid"
                                            *ngFor="let info of employeeMasterLucene">
                                        </option>
                                    </datalist>
                                </div>
                            </div>
                        </div>

                        <!-- button flag  -->
                        <div class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                            <div class="form-group label-floating">
                                <label class="form-label">&nbsp;</label>
                                <button *ngIf="addOrUpdate;else updateButton" type="button" id="navigatetarget"
                                    class="btn btn-primary w-40" style="margin-right: 2vw;margin-left: 2vw;"
                                    (click)="addRouteButton()">
                                    Add
                                </button>
                                <ng-template #updateButton>
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                        style="margin-right: 2vw;margin-left: 2vw;" (click)="updateRouteAssignment()">
                                        Update
                                    </button>
                                </ng-template>
                                <button type="button" class="btn btn-danger w-40"
                                    style="margin-right:1vw;margin-left:1vw" (click)="viewRouteButton()">
                                    View
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"> </div>

                <div class="empty-div-one" id="navigatedest"></div>

                <!-- </form> -->
                <div class="card" *ngIf="add_view_toggle == 'addRoute'">
                    <div class="card-body">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0" style="margin-top:-12px;">
                                    <h3 class="card-title">Site List</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive"
                                        style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                        <div class="scrollsearchresults" id="scroll" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                            [infiniteScrollThrottle]="100" [scrollWindow]="false" id="scrolllength">
                                            <table
                                                class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                id="hr-table">
                                                <thead class="tablerow">
                                                    <tr class="rowcolors stickyatTop">
                                                        <th class="border-bottom-0 w-5 fs-6 ">#</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Site Name
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Assigned To
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Description Name
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Due Date
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- style="max-height: 75vh; overflow: scroll;" -->
                                                    <tr *ngFor="let site of siteList; let i = index">
                                                        <td style="text-align: right;">{{i+1}}</td>
                                                        <td style="min-width: 10vw;max-width: 12vw;text-wrap: wrap;">
                                                            {{site.title}}
                                                        </td>
                                                        <td>
                                                            <input type="search"
                                                                class="form-control col-md-12 col-xs-12"
                                                                name="Employee Name" id="employeename"
                                                                placeholder="Enter Employee Name / ID"
                                                                [value]="site.employeeName == undefined ? (queryName == undefined ? '' : queryName) : site.employeeName"
                                                                autocomplete="off"
                                                                (input)="getEmployeeByNameOrIdTable(i, $event)"
                                                                list="employeeMasterLucene" autofocus="autofocus">
                                                            <datalist id="employeeMasterLucene">
                                                                <option [value]="info.name + ' / ' + info.employeeid"
                                                                    *ngFor="let info of employeeMasterLucene">
                                                                </option>
                                                            </datalist>
                                                        </td>
                                                        <td>
                                                            <input class="form-control" type="text"
                                                                [value]="site.description"
                                                                (change)="trackChange(i, 'description', $event.target.value)"
                                                                required />
                                                        </td>
                                                        <td>
                                                            <input class="form-control" type="date"
                                                                [value]="site.dueDate == undefined ? fromDate : site.dueDate"
                                                                (change)="trackChange(i, 'duedate', $event.target.value)"
                                                                required />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="actionBar pe-4 ps-4" *ngIf="add_view_toggle == 'addRoute' && addOrUpdate">
                    <button class="btn btn-success" (click)="saveChanges()">Save</button>
                </div>

                <div class="card" *ngIf="add_view_toggle == 'viewRoute'">

                    <div class="card-body">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0" style="margin-top:-12px;">
                                    <h3 class="card-title">Route List</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive"
                                        style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                        <div class="scrollsearchresults" id="scroll" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                            [infiniteScrollThrottle]="100" [scrollWindow]="false" id="scrolllength"
                                            (scrolled)="onScroll()">
                                            <table
                                                class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                id="hr-table">
                                                <thead class="tablerow">
                                                    <tr class="rowcolors stickyatTop">
                                                        <th class="border-bottom-0 w-5 fs-6 ">#</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Empl ID</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Empl ID</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Assigned To</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Site Name
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Description
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Due Date</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Status</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- style="max-height: 75vh; overflow: scroll;" -->
                                                    <tr *ngFor="let rd of routeDetail; let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{rd?.employeeid}}</td>
                                                        <td>{{rd?.employeeName}}</td>
                                                        <td style="min-width: 10vw;max-width: 20vw;text-wrap: wrap;">
                                                            {{rd?.title}}
                                                        </td>
                                                        <td style="min-width: 8vw;max-width: 10vw;text-wrap: wrap;">
                                                            {{rd?.description == undefined || null ? '' :
                                                            rd?.description}}</td>
                                                        <td>{{rd?.duedate | date:'mediumDate' }}</td>
                                                        <td>
                                                            <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                                rd?.status === 'Pending' ? 'bg-custom-warning text-custom-dark' :
                                                                rd?.status === 'Close' ? 'bg-custom-red text-custom-dark' :
                                                                rd?.status === 'Assigned' ? 'bg-custom-green text-custom-dark' :
                                                                'bg-custom-warning text-custom-dark'
                                                              " class="badge">
                                                                {{ rd?.status }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                data-method="edit" title="Edit" (click)="editRoute(rd)">
                                                                <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                            <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                data-method="delete" title="Delete"
                                                                (click)="onDeleteHandler(rd.id)"
                                                                data-bs-target="#showAlertWarning">
                                                                <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                    data-original-title="delete"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">

                <span><b>Assigned site will be deleted permanently. Are you sure you want to
                        continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteTask()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeDelRoute()">Cancel</button>
            </div>

        </div>
    </div>
</div>

<div id="showAlertForReassignTask" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <span><b>Route is currently being addressed. Are you sure you want to reassign?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="reassignRoute()">Ok</button>
                <button type="button" class="btn btn-primary" (click)="closeReassignRoute()">Cancel</button>
            </div>

        </div>
    </div>
</div>