<div class="app-content main-content">
    <div class="side-app main-container main-container-margin">
        <div class="alertcomp">

        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcForm" class="form-horizontal form-label-left">
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm">

                                <div class=" col-md-3 col-sm-4 col-xs-4 mb-3">
                                    <label class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="query" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                (change)="onGenerateChange($event.target.value)" autofocus="autofocus"
                                                placeholder="Search Employee Name / ID">
                                            <datalist id="employeeMaster">
                                                <option [value]="empMas.name + ' / ' + empMas.employeeid"
                                                    *ngFor="let empMas of employeeMaster">
                                                </option>
                                            </datalist>
                                        </div>
                                    </div>
                                </div>
                                <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div class="form-group col-md-3 col-sm-4 col-xs-4 px-2">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                        (change)="onStatusChange()" [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
                                    <label class=" p-0 form-label col-xl-4 col-md-4 col-lg-4">Status</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control" name="dateFilter"
                                                    [(ngModel)]="statusFilter">
                                                    <option value="all">All</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Branch</label>
                                        <select id="branch" class="form-control" name="branch"
                                            placeholder="Select Branch" autocomplete="off" autofocus="autofocus"
                                            [(ngModel)]="branch">
                                            <option value="all" selected="selected">All</option>
                                            <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                {{data.branchname}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-3 col-sm-4 col-xs-12 mb-4 mt-1">
                                    <label class="form-label col-xl-2 col-md-2 col-lg-2 m-0 p-0 ">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary  w-100"
                                        (click)="search()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>

                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="navigatedest"></div>
        <!-- Row -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">Payroll Summary</h4>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <form action="#">
                                <div class="table-responsive">
                                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                        [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class=" stickyatTop rowcolors">
                                                    <th class="fixedcolumn secondcolumn"><input type="checkbox"
                                                            style="cursor: pointer;"
                                                            (change)="checkAllSelected($event)" /></th>
                                                    <th class="fixedcolumn">Employee ID</th>
                                                    <th class="secondcolumn">Employee Name</th>
                                                    <th>Salary Date</th>
                                                    <th class="text-center" *ngFor="let sdd of salaryDetail">
                                                        <span>{{sdd.salaryDetailName}}</span>
                                                    </th>
                                                    <th>Pay Days</th>
                                                    <th>LOP</th>
                                                    <th>No Of Days</th>
                                                    <th>Total</th>
                                                    <th>Status</th>
                                                    <th>Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let empInfo of fetchSalaryData ">
                                                    <tr *ngIf="empInfo.salaryDetail && empInfo.salaryDetail.length > 0">
                                                        <td class="fixedcolumn secondcolumn backgroundFW"><input
                                                                type="checkbox" style="cursor: pointer;"
                                                                (change)="checkSelected($event, empInfo)" /></td>
                                                        <td class="fixedcolumn backgroundFW">{{empInfo.employee_id}}
                                                        </td>
                                                        <td class="secondcolumn backgroundSW">{{empInfo.employee_name}}
                                                        </td>
                                                        <td>{{empInfo.salaryDate | date: 'mediumDate' }} to
                                                            {{empInfo.salaryToDate | date: 'mediumDate' }}</td>
                                                        <td class="text-right" *ngFor="let sd of empInfo.salaryDetail">
                                                            <span>{{ sd.salaryamount == '-1' ? ('0'| number: '1.2-2') :
                                                                (sd.salaryamount | number: '1.2-2') }}</span>
                                                        </td>
                                                        <td class="text-right">{{empInfo.pay_days}}</td>
                                                        <td class="text-right">{{empInfo.lop_days}}</td>
                                                        <td class="text-right">{{empInfo.noofdays}}</td>
                                                        <td class="text-right">{{empInfo.total | number: '1.2-2' }}</td>

                                                        <td>

                                                            <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                                empInfo.salaryDetail[0].status === 'PENDING' ? 'bg-custom-warning' :
                                                                empInfo.salaryDetail[0].status === 'APPROVED' ? 'bg-custom-green text-custom-dark' :
                                                                empInfo.salaryDetail[0].status === 'REJECTED' ? 'bg-custom-red text-custom-dark' :
                                                                empInfo.salaryDetail[0].status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                                empInfo.salaryDetail[0].status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                                'bg-custom-warning text-custom-dark'
                                                              " class="badge">
                                                                {{ empInfo.salaryDetail[0].status }}
                                                            </span>
                                                        </td>

                                                        <td><input autocomplete="off" type="text"
                                                                style="min-width: 9.7rem;" name="remark"
                                                                class="form-control" [(ngModel)]="empInfo.reason"
                                                                placeholder="Comments for Rejection"></td>
                                                        <td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="pro-fab btn-float-right">
                                    <button type="button" (click)="onApproveHandler()"
                                        class="btn btn-success">Approve</button>
                                    <button type="button" (click)="onRejectHandler()"
                                        class="btn btn-danger ms-2">Reject</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>