import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'


import 'rxjs';
import { ConstantService, SessionService } from '../services';
import { EmployeeLeaveEncashService } from '../services/employee.leave.encash.service';
import { BooleanService } from '../boolean.service';
import { Observable } from 'rxjs';
import { Menu } from '../services/menu.Service';
declare var $: any;
@Component({
    templateUrl: './sidebar.component.html',
    selector: 'sidebar'
})

export class SidebarComponent implements OnInit {

    planEntryCustomerName: string;
    showMenu: any;
    
    allowedPermissionList: any[] = [];
    rolePermission: any[] = [];
    loggedUsername: string;
    isloggedIn: boolean = false;
    MeId: any;
    showEncashmentScreen: boolean = false;
    loading: boolean;
    booleanobject: Observable<boolean>;
    menuName: string;
    constructor(
        private route: ActivatedRoute,
        private router: Router, private constantService: ConstantService, private sessionService: SessionService, private render: Renderer2,
        private empLeaveEncash: EmployeeLeaveEncashService,private booleanService: BooleanService,private cdr: ChangeDetectorRef,private menuService: Menu) {
        if (window.innerWidth > 992) {
            const ele = document.getElementById('overlayMainComponent')

            const body = document.getElementById('bodytag');

            this.render.listen(ele, 'mouseover', () => {
                ele.style.display = "none";
                if (body.classList.contains('sidenav-toggled1')) {
                    body.classList.remove('sidenav-toggled1')
                    body.classList.add('sidenav-toggled')
                    // const imagele = document.getElementById('companyname');
                    // imagele.classList.add('d-none');
                    // const logo_element =document.getElementById('Scalelogo')
                    // logo_element.classList.remove('d-none');


                    // closing slide sub menu in sidebar
                    // if(document.querySelector('.is-expanded')){
                    //     const slide_sub_menu = document.querySelector('.is-expanded')
                    //     if(slide_sub_menu.querySelector(`.open`)){
                    //         slide_sub_menu.querySelector(`.open`).setAttribute("id","slide_none")
                    //         const slide_sub_menu_lists =  document.getElementById(`slide_none`);
                    //         slide_sub_menu.classList.remove('is-expanded')
                    //         slide_sub_menu_lists.classList.remove('open');
                    //         slide_sub_menu_lists.style.display = "none";
                    //         slide_sub_menu_lists.removeAttribute('id')
                    //     }

                    // }

                    // slide_sub_menu.removeAttribute("is-expanded")
                    // slide_sub_menu_lists.removeAttribute('open')
                }


            })

        }
    }

    ngOnInit() {
        this.menuService.currentMenuName.subscribe(name => {
            this.menuName = name;
          });

        this.rolePermission = this.constantService.getRolePermissions();
        //sujata change
        //   this.router.events.subscribe( (value) => { 
        //     if (value instanceof NavigationEnd) {
        //         console.log(value);

        // this.getLinks(value.url);
        //     }
        // } );
        this.allowedPermissionList = this.sessionService.getRolePermissionList();
        this.loggedUsername = sessionStorage.getItem('username');
        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                if (value.url == '/login' || value.url == '/login/' || value.url == '/' || value.url == '/signup' || value.url == '/companyselection') {
                    this.isloggedIn = false;
                }
                else {
                    this.isloggedIn = true;

                    //today added
                    this.loggedUsername = sessionStorage.getItem('username');
                    console.log(sessionStorage.getItem('username'));

                    //new code for showing or hiding menu
                    if (sessionStorage.getItem('role') == "Employee")
                        //this.MeId = 1;
                        this.MeId = 0;
                    else
                        this.MeId = 0;
                }
                this.updateMenuName(value.url);
            }
        });


        // const result = this.checkconfigDaterange();
        // this.showEncashmentScreen = result;
        // console.log("result", this.showEncashmentScreen)

        // this.checkconfigDaterange().then(result => {
        //     this.showEncashmentScreen = result;
        //     console.log("result", this.showEncashmentScreen);
        // }).catch(error => {
        //     console.error("Error fetching date range", error);
        // });
        this.checkconfigDaterange();
        this.booleanobject = this.booleanService.booleanObservable$;
console.log("booleanobjecttttttttt", this.booleanobject);
    }

    updateMenuName(url: string): void {
        const currentUrl = this.router.url;  // Use the router's current URL directly
        if (currentUrl.includes('customers/customersearch')) {
            this.setMenuName('Customer Search');
        }else if (currentUrl.includes('employeeattendenceproxy')) {
            this.setMenuName('Apply Attendance For Others');
        } else if (currentUrl.includes('customers')) {
            this.setMenuName('Customer');}
         else if (currentUrl.includes('employeemaster')) {
            this.setMenuName('Employee Master');
        }else if (currentUrl.includes('employeeattendenceapproval')) {
            this.setMenuName('Approve Attendance');
        } else if (currentUrl.includes('employeesearch')) {
            this.setMenuName('Employee Search');
        } else if (currentUrl.includes('employeedesignation')) {
            this.setMenuName('Employee Designation');
        }else if (currentUrl.includes('approvesuspensionreport')) {
            this.setMenuName('Approve Employee Suspension');
        }else if (currentUrl.includes('empsuspension')) {
            this.setMenuName('Apply Employee Suspension');
        }else if (currentUrl.includes('suspensionreport')) {
            this.setMenuName('Employee Suspension Report');
        }else if (currentUrl.includes('employeejoiningreport')) {
            this.setMenuName('Employee Joining/Exit Report');
        }else if (currentUrl.includes('employeegroup')) {
            this.setMenuName('Employee Group Allocation');
        }else if (currentUrl.includes('group-shiftallocation')) {
            this.setMenuName('Employee Shift Allocation');
        }else if (currentUrl.includes('empshiftallocated')) {
            this.setMenuName('Employee Shift Allocated');
        }else if (currentUrl.includes('route-definition')) {
            this.setMenuName('Site Assignment');
        }else if (currentUrl.includes('apply-resignation')) {
            this.setMenuName('Apply Resignation');
        }else if (currentUrl.includes('approve-resignation')) {
            this.setMenuName('Approve Resignation');
        }else if (currentUrl.includes('employeeattendencesummary')) {
            this.setMenuName('Swipe Details');
        }else if (currentUrl.includes('employeeattendence')) {
            this.setMenuName('Apply Attendance For Self');
        }else if (currentUrl.includes('apply-extra-timeout')) {
            this.setMenuName('Apply Extra Time Out');
        }else if (currentUrl.includes('approve-extra-timeout')) {
            this.setMenuName('Approve Extra Timeout');
        }else if (currentUrl.includes('apply-overtime')) {
            this.setMenuName('Apply OverTime');
        }else if (currentUrl.includes('approve-overtime')) {
            this.setMenuName('Approve OverTime');
        }else if (currentUrl.includes('employeeleaveapply')) {
            this.setMenuName('Apply Leave For Self');
        }else if (currentUrl.includes('employeeleaveproxy')) {
            this.setMenuName('Apply Leave For Others');
        }else if (currentUrl.includes('apply-comp-off')) {
            this.setMenuName('Apply Comp off');
        }else if (currentUrl.includes('employeeleaveapproval')) {
            this.setMenuName('Approve Leave');
        }else if (currentUrl.includes('employeeleaveencashreport')) {
            this.setMenuName('Annual Leave Encashment Report');
        }else if (currentUrl.includes('approve-comp-off')) {
            this.setMenuName('Approve Comp Off');
        }else if (currentUrl.includes('employeeleaveencash')) {
            this.setMenuName('Apply Annual Leave Encashment');
        }else if (currentUrl.includes('attendancereport')) {
            this.setMenuName('Attendance Report');
        }else if (currentUrl.includes('extra-timeout-report')) {
            this.setMenuName('Extra Time Out Report');
        }else if (currentUrl.includes('overtimereport')) {
            this.setMenuName('OverTime Report');
        }else if (currentUrl.includes('task_report')) {
            this.setMenuName('Site Report');
        }else if (currentUrl.includes('employeeleavestatusreport')) {
            this.setMenuName('Leave Status Report');
        }else if (currentUrl.includes('event_calendar_report')) {
            this.setMenuName('Event Calendar Report');
        }else if (currentUrl.includes('leave-master')) {
            this.setMenuName('Leave');
        }else if (currentUrl.includes('job-master')) {
            this.setMenuName('Job-Level');
        }else if (currentUrl.includes('employeetype')) {
            this.setMenuName('Employee Type');
        }else if (currentUrl.includes('branchMaster')) {
            this.setMenuName('Branch');
        }else if (currentUrl.includes('groupMaster')) {
            this.setMenuName('Group');
        }else if (currentUrl.includes('annual-holidayMaster')) {
            this.setMenuName('Annual Holiday');
        }else if (currentUrl.includes('eventcalendar')) {
            this.setMenuName('Event Calendar');
        }else if (currentUrl.includes('shiftmaster')) {
            this.setMenuName('Shift');
        }else if (currentUrl.includes('departmentetails')) {
            this.setMenuName('Department');
        }else if (currentUrl.includes('designationetails')) {
            this.setMenuName('Designation');
        }else if (currentUrl.includes('document/savedoc')) {
            this.setMenuName('Document Type');
        }else if (currentUrl.includes('noticeboard')) {
            this.setMenuName('Notice Board');
        }else if (currentUrl.includes('site-definition')) {
            this.setMenuName('Site Definition');
        }else if (currentUrl.includes('salary-head-Master')) {
            this.setMenuName('Salary Head ');
        }else if (currentUrl.includes('salary-detail-Master')) {
            this.setMenuName('Salary Detail  ');
        }else if (currentUrl.includes('pay-calculate-rule')) {
            this.setMenuName('Pay Calculation Rule');
        }else if (currentUrl.includes('salaryManulfed')) {
            this.setMenuName('Manually Fed Salary');
        }else if (currentUrl.includes('generte-salary')) {
            this.setMenuName('Generate Payroll');
        }else if (currentUrl.includes('salary-advance-report')) {
            this.setMenuName('Salary Advance Report');
        }else if (currentUrl.includes('salary-advance-aprove')) {
            this.setMenuName('Approve Salary Advance/Loan');
        } else if (currentUrl.includes('salary-advance')) {
            this.setMenuName('Apply Salary Advance');
        }else if (currentUrl.includes('salary-loan-apply')) {
            this.setMenuName('Apply Salary Loan');
        }else if (currentUrl.includes('salaryReportBank')) {
            this.setMenuName('Salary Report to Bank');
        }else if (currentUrl.includes('statutoryReport')) {
            this.setMenuName('Statutory Report');
        }else if (currentUrl.includes('formtreport')) {
            this.setMenuName('Form T Report');
        }else if (currentUrl.includes('esiStatementDownload')) {
            this.setMenuName('ESI statement download');
        }else if (currentUrl.includes('epfabstractreport')) {
            this.setMenuName('EPF Abstract Report');
        }else if (currentUrl.includes('tds-report')) {
            this.setMenuName('TDS Statement Report');
        }else if (currentUrl.includes('downloadReport')) {
            this.setMenuName('Download Export file');
        }else if (currentUrl.includes('pfstatementreport')) {
            this.setMenuName('PF statement report');
        }else if (currentUrl.includes('savingsmaster')) {
            this.setMenuName('Define Savings');
        }else if (currentUrl.includes('it-slab-master')) {
            this.setMenuName('Define IT Slab');
        }else if (currentUrl.includes('incomeTaxCalculator')) {
            this.setMenuName('Income Tax Calculator');
        }else if (currentUrl.includes('expense-master')) {
            this.setMenuName('Expense Submission');
        }else if (currentUrl.includes('expense-approver')) {
            this.setMenuName('Approve Expense');
        }else if (currentUrl.includes('expense-type')) {
            this.setMenuName('Expense Type');
        }else if (currentUrl.includes('expense-report')) {
            this.setMenuName('Expense Report');
        }else if (currentUrl.includes('uploadrulesandpolicy')) {
            this.setMenuName('Policy Documents');
        }else if (currentUrl.includes('policy-submission')) {
            this.setMenuName('Policy Submission');
        }else if (currentUrl.includes('policy-approval')) {
            this.setMenuName('Policy Approve');
        }else if (currentUrl.includes('people')) {
            this.setMenuName('Users');
        }else if (currentUrl.includes('roles/roles')) {
            this.setMenuName('Roles');
        }else if (currentUrl.includes('setting')) {
            this.setMenuName('Setting');
        } else if (currentUrl.includes('chpwdlist')) {
            this.setMenuName('Reset Employee Password');
        }else if (currentUrl.includes('chpwd')) {
            this.setMenuName('Change Password');
        }else if (currentUrl.includes('document/myDocuments')) {
            this.setMenuName('My Document');
        }else if (currentUrl.includes('payslip')) {
            this.setMenuName('My Payslip');
        }else if (currentUrl.includes('bulkuploaddocument')) {
            this.setMenuName('Bulk Upload Documents');
        }else if (currentUrl.includes('approvalinbox')) {
            this.setMenuName('View/Approve Documents');
        }else if (currentUrl.includes('employeedocsearch')) {
            this.setMenuName('Document Submission Report');
        }else if (currentUrl.includes('contract-client')) {
            this.setMenuName('Contract Client');
        } else {
            this.setMenuName('Dashboard');
        }
    }

    toggleSideMenu() {
        var element1 = document.getElementById("sideNavBar");
        var element2 = document.getElementById("overlayer");

        if (element1.style.width === "0px" || element1.style.width === "") {
            element1.style.width = "250px";
            element2.style.display = "block"
        } else {
            element1.style.width = "0";
            element2.style.display = "none"
        }
    }

    changeView() {
        var element = document.getElementById("proApp");
        if (element.className === 'nav-md') {
            element.className = 'nav-sm';
        } else {
            element.className = 'nav-md';
        }
    }

    getUserRole() {
        return this.sessionService.getLoggedUserRole();
    }
    getusername() {
        return this.sessionService.getloggedusername();
    }

    logout() {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('companyId');

        this.toggleSideMenu();

        this.router.navigate(['/login']);
    }

    menuClick(e) {
        // console.log(e);
        // console.log(e?.path)
        // console.log(e?.currentTarget?.childNodes[0]?.getAttribute("id"))

        const allelement = document.querySelectorAll(`.side-menu__item`)
        allelement.forEach(item => {
            if (item.classList.contains('active'))
                item.classList.remove('active');
        })
        const activeelement = document.getElementById(`${e?.currentTarget?.childNodes[0]?.getAttribute("id")}`)
        console.log(activeelement)
        if (activeelement) {
            activeelement.classList.add('active')
        }
        const body = document.getElementById('bodytag');
        // if(body?.classList?.contains('sidenav-toggled')){

        //     console.log("working inside side function")
        //     return
        // }
        // clearing the clicking functions already present on the element
        $("[data-bs-toggle='slide']").off('click');
        $("[data-bs-toggle='sub-slide']").off('click');
        $("[data-bs-toggle='sub-slide2']").off('click');
        // initiating the click function
        $("[data-bs-toggle='slide']").on('click', function (e) {
            const sidebar = document.getElementById('appSidebar3');
            sidebar.style.overflow = 'auto';
            var $this = $(this);
            var checkElement = $this.next();
            var animationSpeed = 300,
                slideMenuSelector = '.slide-menu';
            if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
                checkElement.slideUp(animationSpeed, function () {
                    checkElement.removeClass('open');

                    console.log(ul, "String in else if con : >>>>> 13")

                });
                checkElement.parent("li").removeClass("is-expanded");
            }
            else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
                var parent = $this.parents('ul').first();
                var ul = parent.find('ul:visible').slideUp(animationSpeed);
                ul.removeClass('open');
                var parent_li = $this.parent("li");

                parent.find('li.is-expanded').removeClass('is-expanded');
                checkElement.parent("li").removeClass("is-expanded");
                console.log(ul, "String in else if con : >>>>> 14")

                checkElement.slideDown(animationSpeed, function () {
                    checkElement.addClass('open');
                    parent.find('li.is-expanded').addClass('is-expanded');
                    parent_li.addClass('is-expanded');
                });
            }
            if (checkElement.is(slideMenuSelector)) {

                e.preventDefault();
            }
        });
        // Activate sidebar slide toggle
        $("[data-bs-toggle='sub-slide']").on('click', function (e) {
            // console.log(ul, "String in else if con : >>>>> 12")
            var $this = $(this);
            var checkElement = $this.next();
            var animationSpeed = 300,
                slideMenuSelector = '.sub-slide-menu';
            if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
                checkElement.slideUp(animationSpeed, function () {
                    checkElement.removeClass('open');

                    // console.log(ul, "String in else if con : >>>>> 16")
                });
                checkElement.parent("li").removeClass("is-expanded");
            }

            else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
                var parent = $this.parents('ul').first();
                var ul = parent.find('ul:visible').slideUp(animationSpeed);
                ul.removeClass('open');

                // console.log(ul, "String in else if con : >>>>>")

                var parent_li = $this.parent("li");
                checkElement.slideDown(animationSpeed, function () {
                    // console.log("its inside")
                    checkElement.addClass('open');
                    parent.find('li.is-expanded').removeClass('is-expanded');
                    parent_li.addClass('is-expanded');
                });
            }
            if (checkElement.is(slideMenuSelector)) {
                e.preventDefault();
            }
        });

        // Activate sidebar slide toggle
        $("[data-bs-toggle='sub-slide2']").on('click', function (e) {
            var $this = $(this);
            var checkElement = $this.next();
            var animationSpeed = 300,
                slideMenuSelector = '.sub-slide-menu2';
            if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
                checkElement.slideUp(animationSpeed, function () {
                    checkElement.removeClass('open');
                });
                checkElement.parent("li").removeClass("is-expanded");
            } else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
                var parent = $this.parents('ul').first();
                var ul = parent.find('ul:visible').slideUp(animationSpeed);
                ul.removeClass('open');
                var parent_li = $this.parent("li");
                checkElement.slideDown(animationSpeed, function () {
                    checkElement.addClass('open');
                    parent.find('li.is-expanded').removeClass('is-expanded');
                    parent_li.addClass('is-expanded');
                });
            }
            if (checkElement.is(slideMenuSelector)) {
                e.preventDefault();
            }
        });

        // To close the sub menu dropdown by clicking on inner content
        $('.hor-content').on('click', function () {
            $('.side-menu li').each(function () {
                $('.side-menu ul.open').slideUp(300)
                $(this).parent().removeClass("is-expanded");
                $(this).parent().parent().removeClass("open");
                $(this).parent().parent().prev().removeClass("is-expanded");
                $(this).parent().parent().parent().removeClass("is-expanded");
                $(this).parent().parent().parent().parent().removeClass("open");
                $(this).parent().parent().parent().parent().parent().removeClass("is-expanded");
            })
        })
    }

    mouseOver() {
        if (window.innerWidth > 992) {
            const body = document.getElementById('bodytag');
            // const imagele = document.getElementById('companyname');
            if (body.classList.contains('sidenav-toggled')) {
                body.classList.add('sidenav-toggled1')
                body.classList.remove('sidenav-toggled')
                const overlayMainComponent = document.getElementById('overlayMainComponent');
                overlayMainComponent.style.display = "block";
                // imagele.classList.remove('d-none');
                // const logo_element =document.getElementById('Scalelogo')
                // logo_element.classList.add('d-none');

            }
        }
    }
    mouseOut() {
        if (window.innerWidth > 992) {
            const body = document.getElementById('bodytag');
            if (body.classList.contains('sidenav-toggled1 ')) {
                body.classList.remove('sidenav-toggled1')
                body.classList.add('sidenav-toggled')
            }
        }
    }

    togglesidebarinmobileview() {
        let body = document.querySelector('body');
        if (window.innerWidth <= 992) {
            body.classList.remove('sidenav-toggled');
        }
        if (window.innerWidth > 992) {
            const ele = document.getElementById('sidemenu');
            if (document.querySelector('.is-expanded')) {
                const slide_sub_menu = document.querySelector('.is-expanded')
                if (slide_sub_menu.querySelector(`.open`)) {
                    slide_sub_menu.querySelector(`.open`).setAttribute("id", "slide_none")
                    const slide_sub_menu_lists = document.getElementById(`slide_none`);
                    // console.log(slide_sub_menu , slide_sub_menu_lists);
                    slide_sub_menu.classList.remove('is-expanded')
                    slide_sub_menu_lists.classList.remove('open');
                    slide_sub_menu_lists.style.display = "none";
                    slide_sub_menu_lists.removeAttribute('id')
                }

            }
        }
    }


    // getLinks(url) {
    //     if(url == '/login' || url == '/login/' || url == '/') {

    //         return;
    //     } else if(url == '/employeeswipe'){

    //         var role = sessionStorage.getItem('role');

    //         if(role=='Employee'){
    //             this.showMenu=0;
    //         }
    //         else
    //         {
    //             this.showMenu=1;
    //         }

    //         return;
    //     }



    // }


    notoggle() {

        let body = document.querySelector('body');
        if (window.innerWidth <= 992) {
            body.classList.remove('sidenav-toggled');
        }

        console.log("no toggle ");
    }


    checkconfigDaterange(): any {
        const currentDate = new Date();
        const isoString = currentDate.toISOString();

        this.empLeaveEncash.getconfigDateRange(isoString).subscribe(

            data => {

                this.showEncashmentScreen = data.configFronNTodatecheck
                this.booleanService.emitBooleanValue(this.showEncashmentScreen);
                console.log("Emitting boolean value: ", this.showEncashmentScreen);
                this.cdr.detectChanges();
            },
            error => {
                console.log("Error ", error);
            }
        )
    }

    setMenuName(name: string) {
        this.menuService.setMenuName(name);
      }
}
