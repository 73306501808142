import { Component, OnInit } from '@angular/core';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { SalaryDetailMaster } from 'src/app/models/SalaryMaster';
import { EmployeeAttendenceProxyService } from 'src/app/services/employee-attendence-proxy.service';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';
import { reviewpayslipService } from 'src/app/services/reviewpayslips.service';

@Component({
  selector: 'app-approve-payroll',
  templateUrl: './approve-payroll.component.html',
  styleUrls: ['./approve-payroll.component.css']
})
export class ApprovePayrollComponent implements OnInit {

  query: any;
  employeeMaster: EmployeeMaster[];
  queryName: any;
  uniqueemployeeid: any;
  employeeMasterLucene: EmployeeMaster[];
  selectedempid: any = "all";
  ActiveInativeLuceneFilter: string = 'Active';
  leavingdate: any;

  fetchSalaryData: any;
  approveRejectData: any[] = [];
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  fromDate: string;
  toDate: string;
  showBasedOnPermissionEmployeeStatus: boolean = false;
  permissionList: string[];
  statusFilter: any = "all"
  branch: any = 'all';
  branchInfo: any;

  salaryDetail = new SalaryDetailMaster();
  voidSalaryreason: string;
  generate: boolean;

  salaryApprovalReject: any[];

  constructor(private employeeService: EmployeeAttendenceProxyService, private empLeaveService: EmployeeLeaveService, private empsalaryservice: EmpsalaryslipService, private reviewPay: reviewpayslipService) {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }

  ngOnInit(): void {

    this.getAllBranches();
    this.getSalaryDetail();
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();

  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
        console.log("error in branch ", error);
      }
    );
  }

  getEmployeeByNameOrId() {
    console.log("query", this.query)
    const [name, id] = this.query.split(' / ');
    this.query = this.query.trim();
    this.queryName = name;
    this.uniqueemployeeid = id; // Store the ID if it's available
    console.log("this.selectedempid", this.query);
    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }
    if (!this.query) {
      console.log("old", this.selectedempid);
      this.selectedempid = "all";
      console.log("new", this.selectedempid);
    }
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMaster = []
    }
    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);

          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;

            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging

            // Apply filtering logic based on the selected employee status
            if (this.ActiveInativeLuceneFilter === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.ActiveInativeLuceneFilter === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }

            // If the status is 'All', no filter is applied
            if (this.ActiveInativeLuceneFilter === 'All') {
              filteredEmployees = data.employeeMasterData;
            }

            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;

            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }

          // Now setting the employeeMaster to the filtered results
          this.employeeMaster = this.employeeMasterLucene;

          // If filtered results are not empty, set the selectedempid to the first employee's ID
          if (this.employeeMaster.length > 0) {
            this.selectedempid = this.employeeMaster[0].uniqueemployeeid;
            console.log("Emp Data (First Employee ID): ", this.employeeMaster[0].uniqueemployeeid);
          } else {
            console.log("No employees available after filtering.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      )
    }
  }

  onGenerateChange(info) {
    this.fetchSalaryData = [];
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();

    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }

  search() {
    this.fetchSalary();
  }

  onScroll() {

  }

  onApproveHandler() {
    console.log("approve ", this.approveRejectData);
    // approveRejectPayrollx
    this.reviewPay.approveRejectPayroll(this.approveRejectData, true).subscribe(
      data => {
        this.fetchSalary()
        this.alertmessages("Approved Sucessfully", "Sucess");
      }, error => {
        this.alertmessages("Please Try again something went wrong", "Danger");
      }
    )
  }

  onRejectHandler() {
    console.log("reject ", this.approveRejectData);
    // approveRejectPayroll
    this.reviewPay.approveRejectPayroll(this.approveRejectData, false).subscribe(
      data => {
        this.fetchSalary()
        this.alertmessages("Rejected Sucessfully", "Sucess");
      }, error => {
        this.alertmessages("Please Try again something went wrong", "Danger");
      }
    )
  }

  getSalaryDetail() {
    this.empsalaryservice.getSalaryDetailsForGenerateSalary().subscribe(
      (data) => {
        this.salaryDetail = data.salaryDetailMaster;
      }, (error) => {
        console.log(error)
      }
    )
  }


  fetchSalary() {
    this.fetchSalaryData = [];
    this.voidSalaryreason = '';
    this.generate = false;

    const obj = {
      empId: this.selectedempid,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
      generate: this.generate,
      status: this.statusFilter,
    }

    console.log("fetch Salary function ");
    this.reviewPay.fetchPaySlipByStatusBranch(obj).subscribe(
      data => {
        this.fetchSalaryData = data.fetchSalaryDetails;
        console.log(this.fetchSalaryData);
      }, error => {
        this.alertmessages("Please Try again something went wrong", "Danger");
      }
    )
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  checkAllSelected(event: any) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    if (event.target.checked) {
      this.approveRejectData = [...this.fetchSalaryData];
      checkboxes.forEach((checkbox: any) => checkbox.checked = true);
    } else {
      this.approveRejectData = [];
      checkboxes.forEach((checkbox: any) => checkbox.checked = false);
    }
    console.log("Approve Reject Data after select/deselect all: ", this.approveRejectData);
  }


  checkSelected(event: any, empInfo: any) {
    if (event.target.checked) {
      this.approveRejectData.push(empInfo);
    } else {
      this.approveRejectData = this.approveRejectData.filter(emp => emp.employee_id !== empInfo.employee_id);
    }
    console.log("Approve Reject Data after individual select/deselect: ", this.approveRejectData);
  }


}
